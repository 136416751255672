import producePipeline from 'immer-produce-pipeline';
import {
  buildProductsOfInterest,
  determineIsG99Required,
  getProductsForCrm,
  makeBatteriesArray,
  makePaymentOptionsObject,
  makeProductCostsObject,
  multiply,
  systemTotalRatedPowerKw,
  totalSystemSize,
} from './utils';
import { convertMeterTypeToApiValue } from '../../../../config';
import { divide } from '@zing/neo-common/dist/lib/number';
import { get, getArray, getNumber } from '@zing/neo-common/dist/lib/safe';
// eslint-disable-next-line import/no-cycle
import { getContextCurrentComparison, getValueCurrentComparison } from '../../../../machines/survey.machine';
import { InitialQuoteState } from '../../../../redux/quote/initial-quote-state';
import { makeNarrativePlainText } from '../../../../components/Survey/narrative-default-text';
import { yesNo } from '../../../../redux/quote/yes-no';
import { hybridBatteryTypes } from '@zing/neo-common/dist/accessors/pim-comparison';

const minimizeProduct = product => (product ? { ...product, requires: [] } : null);

const createQuoteObject = (pimSession, current, pimComparison) => {
  const opportunity = get(pimSession, 'opportunity', {});
  const meeting = get(pimSession, 'meeting', {});
  const salesConsultant = get(pimSession, 'salesConsultant', {});
  const { context, value } = current;
  const currentComparisonContext = getContextCurrentComparison(current);
  const currentComparisonValue = getValueCurrentComparison(current);
  const { existingGridConnectedPvOnSite, customerIsEligibleForLowerVatRate } = value;
  const {
    comments,
    leadCategory,
    eonCustomer,
    energyInflationRate,
    exportRate,
    customExportRate,
    existingPvAnnualYieldKwh,
    existingPvInverterTotalRatedOutputKw,
    mpan,
    postcodeZoneId,
    propertyIsListedBuilding,
    propertyType,
    scaffoldComplex,
    externalKitLocation,
    trenchingRequirementMeters,
    rpi,
  } = context;
  const {
    calcs = {},
    dynamic,
    energyConsumptionProfile,
    estimatedElectricityDemandKwh,
    evInstallationType,
    evOlevGrant,
    importRate,
    includeCoolingOffWaiver,
    inverter,
    roofFixing,
    meterType,
    narrative,
    narrativeDefaultText,
    numberOfOccupants,
    paymentType,
    roofs = [],
    solarPvPackage,
    systemTotalInstallPvKwh,
    totalSpecificYieldKwhKwp,
  } = currentComparisonContext;

  // Cashflow
  const cashFlowFirstYear = get(calcs, 'cashflow[0]', null);
  const cashFlowSecondYear = get(calcs, 'cashflow[1]', null);
  const cashFlowThirdYear = get(calcs, 'cashflow[2]', null);
  const cashFlowFourthYear = get(calcs, 'cashflow[3]', null);
  const cashFlowFifthYear = get(calcs, 'cashflow[4]', null);
  const cashFlowLastYear = get(calcs, 'cashflow[24]', null);

  const systemTotalRatedPowerKwValue = systemTotalRatedPowerKw(roofs);
  const annualGenKwh = divide(getNumber(cashFlowFirstYear, 'annualGeneration'), systemTotalRatedPowerKwValue);
  // CASHFLOW VERSION : const exportedWithPvKwh = getNumber(cashFlowFirstYear, 'annualGeneration') - getNumber(cashFlowFirstYear, 'avoidedImport');

  const existingGridConnectedPvOnSiteBool = existingGridConnectedPvOnSite === 'yes';

  // pimComparison items
  const journey = pimComparison.getJourney();
  const battery = pimComparison.getBattery();
  const shouldIgnoreInverter = battery ? hybridBatteryTypes.includes(battery.type) : false;
  const panel = pimComparison.getPanel();
  const chargePoint = pimComparison.getEvChargePoint();
  const optionalAddOnProducts = pimComparison.getAdditionalProducts();
  const mandatoryAddOnProducts = pimComparison.getMandatoryAdditionalProducts();

  // Invoice
  const invoiceParts = [
    get(opportunity, 'invoice_house_number', ''),
    get(opportunity, 'invoice_address_line_1', ''),
    get(opportunity, 'invoice_address_line_2', ''),
    get(opportunity, 'invoice_city', ''),
    get(opportunity, 'invoice_postcode', ''),
  ].filter(x => String(x).trim().length > 0);
  const shouldCopyAddressIntoInvoice = invoiceParts.length === 0;
  const invoiceHouseNumber = shouldCopyAddressIntoInvoice
    ? get(opportunity, 'house_number', '')
    : get(opportunity, 'invoice_house_number', '');
  const invoiceAddressLine1 = shouldCopyAddressIntoInvoice
    ? get(opportunity, 'address_line_1', '')
    : get(opportunity, 'invoice_address_line_1', '');
  const invoiceAddressLine2 = shouldCopyAddressIntoInvoice
    ? get(opportunity, 'address_line_2', '')
    : get(opportunity, 'invoice_address_line_2', '');
  const invoiceCity = shouldCopyAddressIntoInvoice ? get(opportunity, 'city', '') : get(opportunity, 'invoice_city', '');
  const invoiceCounty = shouldCopyAddressIntoInvoice ? get(opportunity, 'county', '') : get(opportunity, 'invoice_county', '');
  const invoiceCountry = shouldCopyAddressIntoInvoice ? get(opportunity, 'country', '') : get(opportunity, 'invoice_country', '');
  const invoicePostcode = shouldCopyAddressIntoInvoice ? get(opportunity, 'postcode', '') : get(opportunity, 'invoice_postcode', '');

  // Calcs
  const annualConsumption = cashFlowFirstYear?.estimatedDemandKwh;
  const selfConsumptionKwh = getNumber(cashFlowFirstYear, 'avoidedImport');
  const combinedSelfConsumptionKwh = getNumber(cashFlowFirstYear, 'combinedSelfConsumptionKwh');
  const importedWithPvKwh = annualConsumption - combinedSelfConsumptionKwh;
  const exportedWithPvKwh = cashFlowFirstYear.annualGeneration - cashFlowFirstYear.avoidedImport;

  const narrativeContent = {
    ...narrativeDefaultText,
    narrative,
  };

  // User can override or use the suggested setting
  const isG99RequiredSuggestion = Boolean(dynamic.calcs.g99.isG99Required);
  const isG99Required = determineIsG99Required(currentComparisonValue.overrideG99, isG99RequiredSuggestion);

  // simulate paymentOptions object
  const paymentOptions = makePaymentOptionsObject(calcs, paymentType);

  // simulate productCosts object
  const productCosts = makeProductCostsObject(calcs);

  const transform = [
    { field: 'house_number', value: opportunity.house_number },
    { field: 'address_line_1', value: opportunity.address_line_1 },
    { field: 'address_line_2', value: opportunity.address_line_2 },

    // #UKSQA-1099 annual_electricity_demand should be `estimated_electricity_demand_kwh` not the user entered value for annualElectricityDemandKwh
    { field: 'annual_electricity_demand', value: currentComparisonContext?.estimatedElectricityDemandKwh, pipeline: [Number] },
    { field: 'annual_electricity_cost', value: currentComparisonContext?.annualElectricityCost, pipeline: [Number] },
    { field: 'appointment_is_phone', value: yesNo(get(meeting, 'telephone_appointment', '') === 'Yes') },
    { field: 'apr_value', value: 0 },
    { field: 'annual_consumption', value: annualConsumption },
    { field: 'battery_rated_capacity', value: pimComparison.getSystemTotalBatteryCapacityKwh() },
    { field: 'city', value: opportunity?.city },
    { field: 'comments', value: comments },
    { field: 'country', value: opportunity?.country },
    { field: 'county', value: opportunity?.county },
    { field: 'current_import_price_per_kwh', value: importRate },
    { field: 'customer_electricity_price', value: importRate },
    { field: 'customer_package_name', value: currentComparisonContext.name },
    { field: 'email', value: opportunity.email },
    { field: 'eon_customer', value: eonCustomer },
    { field: 'energy_inflation_rate', value: energyInflationRate, pipeline: [multiply(100)] },
    { field: 'epc_rating', value: '' },
    { field: 'epc_register_url', value: '' },
    { field: 'estimated_electricity_demand_kwh', value: estimatedElectricityDemandKwh },
    { field: 'export_tariff', value: exportRate },
    { field: 'custom_export_rate', value: customExportRate },
    { field: 'firstname', value: opportunity.firstname },
    { field: 'fit_rate', value: 0 },
    { field: 'install_days', value: 2 },
    { field: 'is_customer_waiver_signed', value: yesNo(includeCoolingOffWaiver) },
    { field: 'is_epc_rating_required', value: '' },
    { field: 'is_payment_plan', value: yesNo(paymentType === 'eonPay') },
    { field: 'lastname', value: opportunity.lastname },
    { field: 'lnumber', value: opportunity.lnumber },
    { field: 'warranty_text', value: get(inverter, 'warranty_text', '') },
    { field: 'meter_type', value: convertMeterTypeToApiValue(meterType) },
    { field: 'mobile', value: opportunity.mobile },
    { field: 'meeting_id', value: meeting.id }, // Fixes the API issue, they need the ID not the app_uuid
    { field: 'listed_conservation', value: yesNo(propertyIsListedBuilding) },
    { field: 'mpan', value: mpan },
    { field: 'number_of_occupants', value: numberOfOccupants },
    { field: 'package_id', value: solarPvPackage?.id || '' },
    { field: 'postcode', value: opportunity.postcode },
    { field: 'lead_category', value: leadCategory },
    { field: 'property_type', value: propertyType },
    { field: 'scaffold_complex', value: yesNo(scaffoldComplex === 'yes') },
    { field: 'external_kit_location', value: yesNo(externalKitLocation === 'yes') },
    { field: 'scaffold_no_of_faces', value: pimComparison.getScaffoldNumberOfFaces() },
    { field: 'scaffold_no_of_storeys', value: pimComparison.getScaffoldNumberOfStories() },
    { field: 'mcs_postcode_region', value: postcodeZoneId },
    { field: 'products_of_interest', value: buildProductsOfInterest(journey) },
    { field: 'rpi', value: rpi, pipeline: [multiply(100)] },
    { field: 'solar_package_name', value: solarPvPackage?.name || '' },
    { field: 'system_total_peak_dc_power', value: pimComparison.getSystemTotalPeakDcPower() },
    { field: 'telephone', value: opportunity.telephone },
    { field: 'title', value: opportunity.title },
    { field: 'total_panels', value: pimComparison.getNumberOfPanels() },
    { field: 'total_specific_yield_kwh_kwp', value: totalSpecificYieldKwhKwp },
    { field: 'total_system_size', value: totalSystemSize(journey, roofs, pimComparison.getBattery()) },
    { field: 'existing_pv_yield_kwh', value: existingGridConnectedPvOnSiteBool ? existingPvAnnualYieldKwh : '' },
    { field: 'size_of_existing_pv_kw', value: existingGridConnectedPvOnSiteBool ? existingPvInverterTotalRatedOutputKw : '' },
    { field: 'grid_connected_pv_on_site', value: existingGridConnectedPvOnSiteBool },
    { field: 'energy_consumption_profile', value: energyConsumptionProfile },

    { field: 'amount_on_which_vat_lower_rate_charged', value: calcs?.totals?.amountOnWhichVatRate1Charged || 0 },
    { field: 'amount_on_which_vat_higher_rate_charged', value: calcs?.totals?.amountOnWhichVatRate2Charged || 0 },

    // sales consultant
    { field: 'sales_consultant_email', value: salesConsultant?.email },
    { field: 'sales_consultant_firstname', value: salesConsultant?.firstname },
    { field: 'sales_consultant_id', value: salesConsultant?.id },
    { field: 'sales_consultant_lastname', value: salesConsultant?.lastname },
    { field: 'sales_consultant_mobile', value: salesConsultant?.mobile },
    // eslint-disable-next-line camelcase
    { field: 'sales_consultant_profile_image_filename', value: salesConsultant?.profile_image_filename },
    { field: 'sales_consultant_telephone', value: salesConsultant?.telephone },

    // invoice items
    { field: 'invoice_house_number', value: invoiceHouseNumber },
    { field: 'invoice_address_line_1', value: invoiceAddressLine1 },
    { field: 'invoice_address_line_2', value: invoiceAddressLine2 },
    { field: 'invoice_city', value: invoiceCity },
    { field: 'invoice_county', value: invoiceCounty },
    { field: 'invoice_country', value: invoiceCountry },
    { field: 'invoice_postcode', value: invoicePostcode },

    // cashflow items
    { field: 'annual_gen_kwh', value: annualGenKwh, pipeline: [Number] },
    { field: 'avoided_import_kwh_year_1', value: getNumber(cashFlowFirstYear, 'avoidedImport') },
    { field: 'benefit_year_20', value: getNumber(cashFlowLastYear, 'cumulativeTotalBenefit') },
    { field: 'co2_saving_kg', value: getNumber(cashFlowFirstYear, 'co2EmissionSavings'), pipeline: [multiply(1000)] },
    { field: 'export_payment_annual', value: getNumber(cashFlowFirstYear, 'exportIncome') },
    { field: 'first_year_benefit_total', value: getNumber(cashFlowFirstYear, 'cumulativeTotalBenefit') },
    { field: 'expected_savings_year_1', value: getNumber(cashFlowFirstYear, 'currentExpectedSavings') },
    { field: 'reduction_in_energy_costs_annual', value: getNumber(cashFlowFirstYear, 'currentExpectedSavings') },
    { field: 'self_consumption_kwh', value: selfConsumptionKwh }, // same value as avoidedImport
    { field: 'self_consumption', value: getNumber(cashFlowFirstYear, 'selfConsumption') },
    { field: 'self_sufficiency_crm', value: getNumber(cashFlowFirstYear, 'selfSufficiency') },
    { field: 'year_1_kwh', value: getNumber(cashFlowFirstYear, 'annualGeneration') },
    { field: 'year_2_kwh', value: getNumber(cashFlowSecondYear, 'annualGeneration') },
    { field: 'year_3_kwh', value: getNumber(cashFlowThirdYear, 'annualGeneration') },
    { field: 'year_4_kwh', value: getNumber(cashFlowFourthYear, 'annualGeneration') },
    { field: 'year_5_kwh', value: getNumber(cashFlowFifthYear, 'annualGeneration') },

    // JSON fields
    { field: 'payment_options', value: paymentOptions },
    { field: 'product_costs', value: productCosts },
    { field: 'batteries', value: journey.hasBattery ? makeBatteriesArray(battery) : [] },
    { field: 'panel', value: journey.hasSolarPV ? minimizeProduct(panel) : {} },
    { field: 'inverter', value: journey.hasSolarPV && !shouldIgnoreInverter ? minimizeProduct(inverter) : {} },
    { field: 'roof_fixing', value: journey.hasSolarPV ? minimizeProduct(roofFixing) : {} },
    { field: 'charge_point', value: minimizeProduct(chargePoint) },
    { field: 'add_on_products', value: [...mandatoryAddOnProducts, ...optionalAddOnProducts] },
    { field: 'product_list', value: getProductsForCrm(calcs?.values || []) },
    { field: 'narrative_content', value: narrativeContent },

    // Payment options fields
    { field: 'balance_as_per_contract', value: getNumber(paymentOptions, 'postDiscount.balanceIncVat') },
    { field: 'credit_amount', value: getNumber(paymentOptions, 'postDiscount.balanceIncVat') },
    { field: 'customer_deposit', value: getNumber(paymentOptions, 'postDiscount.depositIncVat') },
    { field: 'discount', value: getNumber(paymentOptions, 'discountAmount') },
    { field: 'voucher_code', value: get(paymentOptions, 'discountCode') },
    { field: 'final_balance_to_be_paid', value: getNumber(paymentOptions, 'postDiscount.balanceIncVat') },
    { field: 'monthly_payment_amount', value: getNumber(paymentOptions, 'postDiscount.monthlyPayment') },
    { field: 'total_with_vat_before_discount', value: getNumber(paymentOptions, 'beforeDiscount.totalCostIncVat') },
    { field: 'total_without_vat_before_discount', value: getNumber(paymentOptions, 'beforeDiscount.totalCost') },
    { field: 'upfront_payment_amount', value: getNumber(paymentOptions, 'postDiscount.initial') },
    { field: 'upfront_payment_percentage', value: 0 },
    { field: 'vat_amount_lower_rate', value: getNumber(paymentOptions, 'postDiscount.totalVatAtRate1') },
    { field: 'vat_amount_higher_rate', value: getNumber(paymentOptions, 'postDiscount.totalVatAtRate2') },
    { field: 'total_with_vat', value: getNumber(paymentOptions, 'postDiscount.totalCostIncVat') },
    { field: 'total_without_vat', value: getNumber(paymentOptions, 'postDiscount.totalCost') },

    { field: 'agreed', value: false },
    { field: 'agreed_cooling_off_waiver', value: false },
    { field: 'agreed_terms_and_conditions', value: false },
    { field: 'is_g59_required', value: isG99Required }, // not a typo! They changed the standard from G99 to G59
    { field: 'narrative', value: makeNarrativePlainText(narrativeContent) },
    { field: 'system_type', value: '' }, // not required
    { field: 'signed_and_agreed_at', value: '' },

    // Product costs fields
    { field: 'expected_gm_value', value: productCosts.expected_gm_value },
    { field: 'expected_gm_percentage', value: productCosts.expected_gm_percentage },
    { field: 'expected_kit_cost', value: productCosts.expected_kit_cost },
    { field: 'expected_install_cost', value: productCosts.expected_install_cost },
    { field: 'expected_scaffold_cost', value: productCosts.expected_scaffold_cost },
    { field: 'expected_finance_contrib_ex_vat', value: productCosts.finance_cost },

    // Calculated fields
    { field: 'total_estimated_annual_output_kwh', value: systemTotalInstallPvKwh },
    { field: 'total_installed_capacity_kwp', value: totalSpecificYieldKwhKwp },
    { field: 'imported_with_pv_kwh', value: importedWithPvKwh },
    { field: 'exported_with_pv_kwh', value: exportedWithPvKwh },
    { field: 'fit_payment_annual', value: 0 }, // deprecated: (getNumber(calculatedFields, 'total_estimated_annual_output_kwh') * fitRate) / 100;
    { field: 'survey_date', value: new Date() },
    { field: 'attachments', value: roofs.map(({ image }) => image) },

    // items that we need to requoting, store in pim_survey
    {
      field: 'pim_survey',
      value: {
        expectedScaffoldCost: minimizeProduct(pimComparison.getExpectedScaffoldCost()),
        package: minimizeProduct(pimComparison.getPackage()),
        trenchingRequirementMeters,
        customerIsEligibleForLowerVatRate: customerIsEligibleForLowerVatRate === 'yes',
        evInstallationType,
        evOlevGrant,
        isScaffoldingCostOverridden: currentComparisonContext.isScaffoldingCostOverridden,
        overriddenScaffoldingCost: currentComparisonContext.overriddenScaffoldingCost,
        isInstallCostOverridden: currentComparisonContext.isInstallCostOverridden,
        overriddenInstallCost: currentComparisonContext.overriddenInstallCost,
        isKitCostOverridden: currentComparisonContext.isKitCostOverridden,
        overriddenKitCost: currentComparisonContext.overriddenKitCost,
        overriddenKitGsRatio: currentComparisonContext.overriddenKitGsRatio,
      },
    },

    // Unknown
    // sunpathAnalysisPossible, doesnt seem to be exported anywhere
  ];

  const output = { ...InitialQuoteState };
  transform.forEach(row => {
    // process this row into the output object
    output[row.field] = row.pipeline ? producePipeline(row.pipeline)(row.value) : row.value;
  });

  roofs.forEach((roof, index) => {
    const roofId = index + 1;
    output[`annual_generation_year_${roofId}`] = getNumber(roof, 'annualYieldKwhElevation'); // todo: check this, seem to be wrong way round
    output[`annual_yield_kwh_elevation_${roofId}`] = getNumber(roof, 'installedCapacity'); // but they match original data export!!!
    output[`azimuth_elevation_${roofId}`] = getNumber(roof, 'azimuthElevation');
    output[`kwh_kwp_elevation_${roofId}`] = getNumber(roof, `kwhKwpElevation`);
    output[`number_of_panels_elevation_${roofId}`] = getNumber(roof, 'numberOfPanelsElevation');
    output[`number_of_shaded_segments_elevation_${roofId}`] = getNumber(roof, 'numberOfShadedSegmentsElevation');
    output[`rated_power_kw_${roofId}`] = getNumber(roof, 'ratedPowerKw');
    output[`roof_name_${roofId}`] = get(roof, 'roofName', '');
    output[`shaded_segments_${roofId}`] = getArray(roof, 'shadedSegments', []).join(',');
    output[`shading_factor_elevation_${roofId}`] = getNumber(roof, 'shadingFactorElevation');
    output[`slope_elevation_${roofId}`] = getNumber(roof, 'slopeElevation');
  });

  // console.log('productCosts', productCosts);
  // console.log('output:', output);

  return output;
};

export default createQuoteObject;
